<template>
    <div>
        <model-window v-if="showModel" @close="showModel = false" />
        <div class="new-summary update" v-if="loaded">
            <div class="header-div"><img loading="lazy"
                    src="https://uploads-ssl.webflow.com/657b2233d6a286f5c9f3844c/65a8b55c0b759f07aa5e3058_yara%20placeholder.svg"
                    alt="" class="image-242">
                <a href="#" class="link-block-18 w-inline-block">
                    <div class="text-block-342">Need Help</div>
                    <div class="help-div">
                        <div class="text-block-415">?</div>
                    </div>
                </a>
            </div>
            <div class="dropdown-sum">
                <div class="order-summary-div">
                    <div data-delay="0" data-hover="false" class="order-dropdown w-dropdown">
                        <div class="dropdown-sum-toggle w-dropdown-toggle" @click.prevent="dropOpen = !dropOpen"
                            v-bind:class="{ 'w--open': dropOpen }">
                            <div class="icon-19 w-icon-dropdown-toggle"></div>
                            <div class="div-block-1282">
                                <div class="text-block-327">{{ people.plan }} Monthly</div>
                                <div class="no-discount-price" v-if="people.discount > 0">${{ people.amount }}</div>
                                <div class="text-block-328">${{ getPrice }}</div>
                            </div>
                            <div class="div-block-1283">
                                <div class="text-block-333">Shipping</div>
                                <div class="text-block-333">FREE</div>
                            </div>
                        </div>
                        <nav class="dropdown-summary w-dropdown-list" v-bind:class="{ 'w--open': dropOpen }">
                            <div class="div-block-269 _1 new summary-drop">
                                <div class="div-block-960 new summary-drop">
                                    <div class="div-block-976">
                                        <div class="div-block-974"><img sizes="100vw"
                                                srcset="@/assets/images/cart-image_TrizepatideVial-p-500.jpg 500w, @/assets/images/cart-image_TrizepatideVial-p-800.jpg 800w, @/assets/images/cart-image_TrizepatideVial.jpg 1000w"
                                                alt="" src="@/assets/images/cart-image_TrizepatideVial.jpg"
                                                loading="lazy" class="image-36">
                                            <div class="div-block-975">
                                                <div class="text-block-161">1</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="text-block-87">{{ people.plan }} Program</div>
                                            <div class="text-block-162">Billed Monthly <span v-if="people.discount > 0">
                                                    Month 2 onwards ${{ people.amount }} </span></div>
                                        </div>
                                    </div>

                                    <div class="text-block-329">${{ getPrice }}</div>
                                </div>
                            </div>
                            <div class="form-block-9 drop-sum w-form">
                                <form @submit.prevent="checkCoupon()">
                                    <div class="div-block-977 checkout step-1">
                                        <div class="div-block-1368"><input class="text-field-5 coupon new w-input"
                                                v-bind:class="{ 'text-danger': !valid_coupon }" v-model="coupon"
                                                maxlength="256" name="field-3" data-name="Field 3"
                                                placeholder="Coupon Code" type="text" id="field-3" required>
                                            <input type="submit" class="button-15 new w-button" value="Apply">
                                        </div>
                                        <div class="coupons-div" v-if="people.discount > 0">
                                            <div class="discount">
                                                <div class="text-block-426"><sup>$</sup>{{ people.discount }} Off</div>
                                                <a href="#" class="remove-discount w-inline-block"
                                                    @click.prevent="people.discount = 0">
                                                    <div class="text-block-427">x</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div class="w-form-fail">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                            <div class="div-block-269 _2 new drop-sum">
                                <div class="div-block-962 new">
                                    <div class="text-block-331">Subtotal</div>
                                    <div class="text-block-332">${{ getPrice }}</div>
                                </div>
                                <div class="div-block-962 new">
                                    <div class="text-block-331">Shipping</div>
                                    <div class="text-block-332">FREE</div>
                                </div>
                            </div>
                            <div class="div-block-269 _2 new drop-sum total">
                                <div class="div-block-962 new drop-sub">
                                    <div class="text-block-88 _2 after"><strong>Total</strong></div>
                                    <div class="text-block-163"><strong class="bold-text-3">${{ getPrice }}</strong>
                                    </div>
                                </div>
                                <div class="text-block-341">Subscription fee may increase if prescribed a higher dose of
                                    medication.</div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div class="text-block-336 step-1 update">(Tap above to access full order details)</div>
            </div>
        </div>
        <div class="div-block-263">
            <div class="div-block-265">
                <div class="div-block-266 new last step1 update"><img loading="lazy"
                        src="https://uploads-ssl.webflow.com/657b2233d6a286f5c9f3844c/65a8b55c0b759f07aa5e3058_yara%20placeholder.svg"
                        alt="" class="image-31 summary">
                    <div class="checkout-div step-1">
                        <div class="div-block-1291 step-1">
                            <div class="div-block-1678">
                                <div class="text-block-250 checkout update">Lock in your spot, today!</div>
                                <a data-w-id="cb7132b2-ab8c-b411-05e0-7b9d6fdf1d3d" href="#" @click.prevent="showPopUp = true;" class="popup-link">Click for Details</a>
                            </div>
                            <p class="paragraph-128">We pre-authorize your card to hold your place with the doc. Don’t
                                worry—we don&#x27;t charge until you give the thumbs-up &amp; meds ship. </p>
                        </div>
                        <div class="summary-mobile">
                            <div class="div-block-269 _2">
                                <div class="text-block-88 _2">Total Due</div>
                                <div class="text-block-88 _2">$398</div>
                            </div>
                            <div class="div-block-269 _2">
                                <div>
                                    <div class="text-block-88 _2 after">Total After Trial</div>
                                </div>
                                <div class="div-block-959">
                                    <div class="text-block-88 _2 after">$195/mo</div>
                                    <div class="text-block-140">*plus $.25 per match</div>
                                </div>
                            </div>
                        </div>
                        <div class="w-form">
                            <form @submit.prevent="getCardToken"
                                class="step-2-left-payment-form new-checkout new-mobile checkout-desk step-1">
                                <div class="div-block-1292 step-1"><label for="email-2"
                                        class="field-label-4 step-1 update">Contact</label><input v-model="people.email"
                                        class="text-field-7 new step-1 w-input" maxlength="256" name="email-2"
                                        data-name="Email 2" placeholder="Email Address" type="email" id="email-2"
                                        required><label for="email-3" class="field-label-4 step-1 update">Shipping
                                        Information</label>
                                    <div class="div-block-1363">
                                        <div class="div-block-1364"><input class="form-text-field w-input"
                                                v-model="people.first_name" maxlength="256" name="first-name"
                                                data-name="first name" placeholder="First Name" type="text"
                                                id="first-name" required></div>
                                        <div class="div-block-1364"><input class="form-text-field w-input"
                                                v-model="people.last_name" maxlength="256" name="Last-Name"
                                                data-name="Last Name" placeholder="Last Name" type="text" id="Last-Name"
                                                required></div>
                                    </div>

                                    <google-places-autocomplete @address-updated="getAddressData" />
                                    <label for="email-3" class="field-label-4 step-1 update">Phone Number</label>

                                    <div class="div-block-1366 mobile-entry">
                                        <input class="phone-number-field _1 step-1 w-input" pattern="[0-9]*"
                                            maxlength="3" minlength="3" @keyup="focusNext"
                                            v-model="people.phone_part.part1" placeholder="(000)" type="text"
                                            id="field-2" required>
                                        -<input class="phone-number-field step1 w-input"
                                            v-model="people.phone_part.part2" pattern="[0-9]*" maxlength="3"
                                            minlength="3" @keyup="focusNext" placeholder="000" type="text" id="field-2"
                                            required>
                                        -<input class="phone-number-field _2 step-1 w-input" pattern="[0-9]*"
                                            maxlength="4" minlength="4" @keyup="focusNext" name="field-2"
                                            v-model="people.phone_part.part3" data-name="Field 2" placeholder="0000"
                                            type="text" id="field-2" required>
                                    </div>

                                    <div class="div-block-1292 update">
                                        <div class="div-block-853 new update">
                                            <div class="text-block-80 skinny update">Credit Card</div>
                                            <div class="div-block-1655"><img width="35" loading="lazy" alt=""
                                                    src="@/assets/images/visa.png" class="image-7 update"><img
                                                    width="35" loading="lazy" alt=""
                                                    src="@/assets/images/mastercard.png" class="image-7 update"><img
                                                    width="35" loading="lazy" alt="" src="@/assets/images/AmEx.png"
                                                    class="image-7 update"><img width="35" loading="lazy" alt=""
                                                    src="@/assets/images/discover.png" class="image-7 update"></div>
                                        </div>
                                        <div>
                                            <div id="card-element" class="myelement" ref="myElement"></div>

                                        </div>
                                        <button class="submit-button-3 new update w-button" :disabled="submitted"
                                            style="display: flex;justify-content: center; align-items: center;">
                                            <span v-if="submitted">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
                                                    preserveAspectRatio="xMidYMid" width="35" height="35"
                                                    style="shape-rendering: auto; display: block; background: transparent;"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                    <g>
                                                        <circle stroke-dasharray="155.50883635269477 53.83627878423159"
                                                            r="33" stroke-width="11" stroke="#faf8f8" fill="none"
                                                            cy="50" cx="50">
                                                            <animateTransform keyTimes="0;1" values="0 50 50;360 50 50"
                                                                dur="1s" repeatCount="indefinite" type="rotate"
                                                                attributeName="transform"></animateTransform>
                                                        </circle>
                                                        <g></g>
                                                    </g>
                                                </svg>
                                            </span>
                                            <span class="" v-else>Checkout Now -&gt;</span>
                                        </button>

                                        <div class="w-form-fail" style="display:block" v-if="errorReason">
                                            <div>{{ errorMessage }}</div>
                                        </div>
                                        <input type="hidden" name="xxTrustedFormCertUrl" id="xxTrustedFormCertUrl_0"
                                            ref="trustedFormUrl" value="">
                                        <p class="paragraph-123">By clicking 'Checkout Now,' you acknowledge and agree
                                            to pre-authorize your card for the specified amount. Upon approval and
                                            acceptance of your prescription, the authorized amount will be charged. If
                                            the prescription is not approved, the pre-authorization will be released
                                            within 7 days. You also agree to the <a
                                                href="https://yarahealth.helpscoutdocs.com/article/22-refund-payment-return-policy"
                                                target="_blanks"> refund and payment terms.</a></p>
                                        <div class="secure-div update">
                                            <div class="div-block-1369"><img src="@/assets/images/icon_lock.svg"
                                                    loading="lazy" alt="" class="image-277">
                                                <div class="text-block-432">SSL SECURED PAYMENT</div>
                                            </div>
                                            <div class="text-block-433">Your information is protected by 256-bit SSL
                                                encryption</div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="div-block-1288 hide">
                    <div class="div-block-1348">
                        <a href="#" class="link-block-22 w-inline-block">
                            <div class="text-block-406">Need Help</div>
                            <div class="help-div">
                                <div class="text-block-415">?</div>
                            </div>
                        </a>
                    </div>
                    <div class="summary-desk step-2">
                        <div class="div-block-269 _1 new">
                            <div class="div-block-960 new">
                                <div class="div-block-976">
                                    <div class="div-block-974">
                                        <div class="checkout-product-div"></div>
                                        <div class="div-block-975">
                                            <div class="text-block-161">1</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="text-block-87">{{ people.plan }} Program</div>
                                        <div class="text-block-162">Billed Monthly <span v-if="people.discount > 0"> Month
                                                2 onwards ${{ people.amount }} </span></div>
                                    </div>
                                </div>
                                <div class="no-discount-price" v-if="people.discount > 0">
                                    <strong>${{ people.amount }}</strong>
                                </div>
                                <div class="text-block-347">${{ getPrice }}</div>
                            </div>
                        </div>
                        <div class="div-block-269 _1 coupon new">
                            <div class="form-block-9 w-form">
                                <form @submit.prevent="checkCoupon()">
                                    <div class="div-block-977 checkout step-1">
                                        <div class="div-block-1368"><input class="text-field-5 coupon new w-input"
                                                maxlength="256" name="field-3" data-name="Field 3"
                                                v-bind:class="{ 'text-danger': !valid_coupon }" v-model="coupon"
                                                placeholder="Coupon Code" type="text" id="field-3" required>
                                            <input type="submit" class="button-15 new w-button" value="Apply">
                                        </div>
                                        <div class="coupons-div" v-if="people.discount > 0">
                                            <div class="discount">
                                                <div class="text-block-426"><sup>$</sup>{{ people.discount }} Off</div>
                                                <a href="#" class="remove-discount w-inline-block"
                                                    @click.prevent="people.discount = 0">
                                                    <div class="text-block-427">x</div>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                                <div class="w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div class="w-form-fail">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                            <div class="div-block-269 _2 new">
                                <div class="div-block-962 new">
                                    <div class="text-block-88 _2 after"><strong>Subtotal</strong></div>
                                    <div class="text-block-163">${{ getPrice }}</div>
                                </div>
                                <div class="div-block-962 new">
                                    <div class="text-block-88 _2 after"><strong>Shipping</strong></div>
                                    <div class="text-block-163">FREE</div>
                                </div>
                            </div>
                        </div>
                        <div class="div-block-1289">
                            <div class="text-block-348">Total</div>
                            <div class="text-block-348">${{ getPrice }}</div>
                        </div>
                        <div class="text-block-90">Monthly Access. <span class="text-span-13">Cancel anytime. </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup-modal _1" style="display: block;" v-if="showPopUp">
            <div>
                <div class="next-steps-box checkout">
                    <div class="close-pop-up-div">
                        <a data-w-id="0e37543e-65c8-adf2-4f38-b6a50eb182b7" href="#" @click.prevent="showPopUp = false"
                            class="link-47">Close X</a>
                    </div>
                    <div class="next-step-header-div">
                        <div class="text-block-715 checkout">What&#x27;s Next?</div>
                        <div class="text-block-716 checkout">You are about to join thousands losing weight with
                            Semaglutide &amp; Tirzepatide.</div>
                    </div>
                    <div class="step-entry-div checkout">
                        <div class="div-block-1662 checkout"><img src="@/assets/images/icon_card.png" loading="lazy"
                                alt="" class="image-379 checkout"></div>
                        <div class="div-block-1663">
                            <div class="text-block-713 checkout">Pre-Authorize Your Card</div>
                            <div class="text-block-714 checkout">These medications are in high demand. We will need to
                                pre-authorize your card today to secure your medication and telemed appointment. (Funds
                                are held until shipment. If denied, funds will be released back to you.)</div>
                        </div>
                    </div>
                    <div class="step-entry-div checkout">
                        <div class="div-block-1662 checkout"><img src="@/assets/images/icon_clipboard.png"
                                loading="lazy" alt="" class="image-377 checkout"></div>
                        <div class="div-block-1663">
                            <div class="text-block-713 checkout">Complete The Medical Intake</div>
                            <div class="text-block-714 checkout">Once your payment is made, we will send you an email
                                with instructions on how to access your Yara portal and complete a few medical questions
                                for the doctor.</div>
                        </div>
                    </div>
                    <div class="step-entry-div checkout">
                        <div class="div-block-1662 checkout"><img src="@/assets/images/stepsimage_teledoc.png"
                                loading="lazy" sizes="100vw"
                                srcset="@/assets/images/stepsimage_teledoc-p-500.png 500w, @/assets/images/stepsimage_teledoc.png 641w"
                                alt="" class="image-378 checkout"></div>
                        <div class="div-block-1663">
                            <div class="text-block-713 checkout">Doctor&#x27;s Review</div>
                            <div class="text-block-714 checkout">The doctor will review your chart. (Usually within 5
                                hours including weekends.)</div>
                        </div>
                    </div>
                    <div class="step-entry-div checkout">
                        <div class="div-block-1662 checkout"><img src="@/assets/images/icon_van.png" loading="lazy"
                                alt="" class="image-386"></div>
                        <div class="div-block-1663">
                            <div class="text-block-713 checkout">Approval and Shipping</div>
                            <div class="text-block-714 checkout">Once approved, our FDA-registered pharmacy will ship
                                your ice-packed medication with UPS next-day rush delivery. Orders ship same day, except
                                Fridays. A tracking number will be provided to your email address when your medication
                                ships.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BasisTheory
} from "@basis-theory/basis-theory-js";
const bt = await new BasisTheory().init("key_prod_us_pub_9drv27VKwcUVoP5BNnnyKZ", {
    elements: true
});
import GooglePlacesAutocomplete from '../../common/GooglePlacesAutocomplete.vue'
import modelWindow from '../../common/modelWindow.vue'
export default {
    props: ['baseUrl', 'mainlogo', 'iconSVGColor', 'checkoutlogo'],
    components: {
        GooglePlacesAutocomplete,
        'model-window': modelWindow
    },
    data() {
        return {
            loaded: false,
            showModel: false,
            showPopUp: false,
            submitted: false,
            dropOpen: false,
            discount: 0,
            valid_coupon: true,
            coupon: '',
            hasError: false,
            emailError: false,
            addressError: false,
            errorReason: false,
            errorMessage: '',
            people: {},
            addressInfo: {
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                zip: ''
            },
        }
    },
    mounted() {
        this.checkUrlParameters();
        this.scrollToTop();
    },
    computed: {
        getPrice() {

            let discountedPrice = this.people.amount - this.people.discount;
            if (discountedPrice % 1 !== 0) {
                discountedPrice = Math.round(discountedPrice * 100) / 100;
            }
            return discountedPrice;
        },
        isMobile() {
            return window.innerWidth <= 768;
        },
        validateDate() {
            const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/;
            let dob = this.people.dob;
            return regex.test(dob) && this.isValidDate(dob)
        },
        validateEmail() {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return regex.test(this.people.email)
        },
        isAddressComplete() {
            const {
                address_line_1,
                city,
                state,
                zip
            } = this.addressInfo;
            return address_line_1 && city && state && zip;
        }
    },
    methods: {
        scrollToTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },
        checkUrlParameters() {
            let vm = this;
            vm.people = {
                ...vm.$route.query,
            };
            vm.people.phone_part = {
                part1: vm.people.phone.slice(0, 3),
                part2: vm.people.phone.slice(3, 6),
                part3: vm.people.phone.slice(6, 10),
            };
            if (this.$route.query.plan == 'Tirzepatide') {
                this.people.amount = 449;
            } else {
                this.people.amount = 299;
            }

            if (!('discount' in this.people)) {
                this.people.discount = 0;
            }
            setTimeout(function () {
                vm.loaded = true;
                vm.createCardElement();
            }, 100);

        },
        isValidDate(dob) {
            const [month, day, year] = dob.split('/').map(Number);
            const date = new Date(year, month - 1, day);
            return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
        },
        getCardToken() {
            this.errorReason = false;
            if (!this.validateEmail) {
                this.errorReason = true;
                this.errorMessage = "Please enter valid email address."
                return false;
            }
            if (!this.isAddressComplete) {
                this.errorReason = true;
                this.errorMessage = "Please enter shipping address."
                return false;
            }

            if (["MS", "LA"].includes(this.addressInfo.state)) {
                this.$router.push({
                    name: 'SorryPage'
                });
            } else {
                this.submitted = true;
                this.errorReason = false;
                bt.tokens
                    .create({
                        type: "card",
                        data: this.cardElement,
                    })
                    .then((token) => {
                        this.people.cardToken = token.id;
                        this.people.cardDetails = token.data;
                        this.submitForm();
                        this.addPixelScript();
                    })
                    .catch((error) => {
                        this.errorReason = true;
                        this.submitted = false;
                        this.errorMessage = error.data.message ?? "Invalid Card Details"
                    });
            }


        },
        submitForm() {
            this.people.phone = `${this.people.phone_part.part1}${this.people.phone_part.part2}${this.people.phone_part.part3}`;
            const urlvalue = this.$refs.trustedFormUrl.value;
            window.axios.post(`${this.baseUrl}/flow-three-checkout`, this.people, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data.status == "success") {
                    this.$router.push({
                        name: 'MedicalQuestions',
                        query: {
                            id: this.people.id,
                            state: this.addressInfo.state,
                            amount: this.people.amount
                        }
                    });
                    this.saveAddress(urlvalue);
                    this.registerAffiliate();
                } else {
                    this.errorReason = true;
                    this.errorMessage = response.data.message ?? "Insufficient account balance."
                    this.submitted = false;
                }
            }).catch((error) => {
                this.errorReason = true;
                this.errorMessage = error.data.message ?? "Internal error occurred."
                this.submitted = false;
            });

        },
        addPixelScript() {
            const pixelScript = document.createElement('script');
            pixelScript.src = 'https://pxl.iqm.com/i/pixel/3c294fa8-6df0-4bb2-b793-83655bb9aee2';
            pixelScript.async = true;
            // Append the script to the head
            document.head.appendChild(pixelScript);
        },
        registerAffiliate() {
            let userAgent = '';
            try {
                userAgent = navigator.userAgent
            } catch (error) {
                console.log(error)
            }
            let affilate_tracking = {
                "id": '',
                "affiliate_id": this.$route.query.id,
                "utm_source": this.$route.query.utm_source,
                "tid": this.$route.query.tid,
                "discount": this.people.discount,
                "med": this.people.plan,
                "state": this.$route.query.state,
                "amount": this.people.amount,
                "pay_terms": 1,
                "userAgent": userAgent,
                "ip_address": JSON.parse(localStorage.getItem("ip_address")),
                "callcenter_source": 0
            }
            localStorage.setItem("aff_tracking", JSON.stringify(affilate_tracking));
        },
        letfbKnow(people) {
            try {
                window.axios.post(`${this.baseUrl}/let-fb-know`, {
                    first_name: people.first_name,
                    last_name: people.last_name,
                    email: people.email,
                    ip_address: people.ip_address,
                    userAgent: navigator.userAgent,
                    utm_source: people.utm_source,
                    event: "AddToCart",
                });
            } catch (error) {
                console.log(error)
            }

        },
        checkCoupon() {
            this.discount = 0;
            this.valid_coupon = true;
            window.axios.post(`${this.baseUrl}/validate-coupon`, {
                coupon: this.coupon
            }).then((response) => {
                if (response.data.status == 'success') {
                    this.valid_coupon = true;
                    this.couponapplied = true;
                    if (response.data.c_type == 1) {
                        this.discount = response.data.c_val;
                    } else {
                        this.discount = Math.round((this.people.amount * response.data.c_val) / 100);
                    }
                } else {
                    this.valid_coupon = false;
                }
                this.mkey++;
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
        },
        focusNext(event) {
            const currentInput = event.target;
            const nextInput = currentInput.nextElementSibling;
            const prevInput = currentInput.previousElementSibling;
            if (event.key === "Backspace" && currentInput.value === "") {
                if (prevInput) {
                    prevInput.focus();
                }
            } else if (currentInput.value.length === currentInput.maxLength && nextInput) {
                nextInput.focus();
            }
        },
        saveAddress(url) {
            try {
                window.axios.post(`${this.baseUrl}/save-address-flow3/${this.$route.query.id}`, this.addressInfo);
                window.axios.post(`${this.baseUrl}/trust-form`, { "user_id": this.$route.query.id, "url": url });
            } catch (error) {
                console.log(error)
            }
        },
        getAddressData(address) {
            if (["MS", "LA"].includes(address.state)) {
                this.showModel = true;
            } else {
                this.addressInfo.address_line_1 = address.street;
                this.addressInfo.address_line_2 = address.address_line2;
                this.addressInfo.city = address.city;
                this.addressInfo.state = address.state;
                this.addressInfo.zip = address.zip;
            }
        },
        createCardElement() {
            this.cardElement = bt.createElement('card');
            this.cardElement.mount('#card-element');
        },
    },
    created() {
        try {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'add_to_cart');
            } else {
                console.warn('gtag is not available. Make sure Google Tag Manager or gtag.js is properly loaded.');
            }
        } catch (error) {
            console.error('Error sending Google Tag Manager event:', error);
        }
    }
}
</script>
<style scoped>
.myelement {
    border: 1px solid #002646;
    border-radius: 6px;
    padding: 5px 0 5px 15px;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
    height: 45px;
    align-content: center;
    
}
@media screen and (max-width: 479px) {
.form-text-field {
    border: 2px solid var(--field-box-outline);
}
.myelement{
    border: 2px solid #002646;
}
.popup-link {
    text-align: center;
}
}
</style>